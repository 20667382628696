






















































































@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.image-component {
    position: relative;
    overflow: hidden;

    // Width and height should be set by the parent.
    img {
        object-fit: scale-down;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
