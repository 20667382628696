.split-inputs {
    display: flex;
    flex-direction: row;

    & > div {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        margin: 0 20px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 699px) {
        display: block;

        & > div {
            margin: 0;
        }
    }
}
