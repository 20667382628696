






































.loading-button {
    position: relative;
    display: inline-block;

    &.block {
        display: block;
    }

    &.bottom {
        margin-top: auto;
        padding-top: 15px;
    }

    > div:first-child {
        padding-right: 0px;
        transition: padding-right 0.25s;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

     > div:last-child {
        position: absolute;
        opacity: 0;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
        transition: transform 0.25s, opacity 0.25s;
    }

    &.loading {
        > div:first-child {
            padding-right: 40px;
        }
        > div:last-child {
            opacity: 1;
            transform: translate(0, -50%);
        }
    }
}
