

















































































































































































































































































































































































































































































@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.webshop-view {
    /*display: flex;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;
    min-height: calc(var(--vh, 1vh) * 100);*/
    --box-width: 600px;

    .webshop-banner {
        // height: 0px;
        // width: 100%;
        margin: 0 auto;
        // padding-bottom: 370 / 720 * 100%;
        background: $color-gray-3;
        border-radius: $border-radius;
        margin-bottom: 30px;
        margin-top: 0px;
        position: relative;

        @media (max-width: 801px) {
            margin-bottom: 20px;
        }

        img {
            // position: absolute;
            border-radius: $border-radius;
            height: auto;
            width: 100%;
            display: block;
        }
    }

    .webshop-layout {
        max-width: 800px;
        margin: 0 auto;
        padding-bottom: 100px;

        .full-product-box {
            background: $color-background;
            --color-current-background: #{$color-background};
            --color-current-background-shade: #{$color-background-shade};
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 20px 50px $color-shadow, inset 0px 0px 0px 1px $color-shadow;
            border-radius: $border-radius-modals;
            overflow: hidden;
            position: relative;
            z-index: 0;

            @media (max-width: 550px) {
                margin: 0 calc(-1 * var(--st-horizontal-padding, 40px));
                border-radius: 0;
            }

            // Reset view insets
            --st-safe-area-top: 0px;
            --st-safe-area-bottom: 0px;
            --keyboard-height: 0px;
            --bottom-padding: 0px;

            > .st-view > main{
                // Disable scroll view
                overflow: hidden;
            }
        }

        &.Default {
            > .products hr {
                --st-horizontal-padding: 0px;
            }
        }

        &.Split {
            > .products {
                padding: 40px var(--st-horizontal-padding, 20px) 30px var(--st-horizontal-padding, 20px);

                background: $color-background;
                --color-current-background: #{$color-background};
                --color-current-background-shade: #{$color-background-shade};
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 20px 50px $color-shadow, inset 0px 0px 0px 1px $color-shadow;
                border-radius: $border-radius-modals;
                overflow: hidden;
                position: relative;
                z-index: 0;

                @media (max-width: 550px) {
                    margin: 0 calc(-1 * var(--st-horizontal-padding, 40px));
                    border-radius: 0;
                }
            }

            @media (min-width: 1000px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: start;
                gap: 30px;
                max-width: 1200px;
                margin: 0 auto;

                .webshop-header {
                    > h1:first-child {
                        padding-top: 40px;
                    }
                }

                > .full-product-box {
                    // position: sticky;
                    // top: 0px;
                }
            }

            @media (min-width: 1200px) {
                gap: 50px;
            }
        }
    }

    .webshop-header {
        --st-horizontal-padding: 0px;
        padding-bottom: 30px;

        h1 {
            @extend .style-title-semihuge;
            padding-bottom: 10px;
        }

        .description {
            @extend .style-description;
            white-space: pre-wrap;
        }
    }
    
    .stamhoofd-footer {
        padding-top: 15px;
        @extend .style-description-small;

        a {
            white-space: normal;
            text-overflow: initial;
            height: auto;
            line-height: 1.4;
        }

        strong {
            color: $color-primary-original;
        }
    }
}
