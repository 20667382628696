







































































.checkbox.manual {
    pointer-events: none;
}
