
















































.product-grid {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr;
}

.enable-grid .product-grid {
    @media (min-width: 801px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;

        &.single {
            grid-template-columns: 1fr;
        }
    }
}
