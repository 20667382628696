@use '@stamhoofd/scss/base/variables' as *;
@use '@stamhoofd/scss/base/responsive-helpers' as *;
@use '@stamhoofd/scss/components/icons' as *;

.error-box {
    background: $color-error-background;
    color: $color-error-dark;

    &::before,
        .inline-link {
        color: $color-error;
    }
}

.error-box-parent .error-box {
    margin: 0;
}

.warning-box {
    background: $color-warning-background;
    color: $color-warning-dark;

    &::before {
        color: $color-warning;
    }

    .inline-link {
        color: $color-warning-dark;
        font-weight: $font-weight-bold;
        text-decoration: underline;
    }
}

.info-box {
    background: $color-primary-background;
    color: $color-dark;

    &::before,
        .inline-link {
        color: $color-primary;
    }
}

.success-box {
    background: $color-success-background;
    color: $color-success-dark;

    &::before, .inline-link {
        color: $color-success;
    }
}

.loading-box {
    background: $color-primary-background;
    color: $color-dark;
    
    > .spinner-container {
        position: absolute;
        color: $color-primary;
        left: 12px;
        top: 50%;
        transform: translate(0, -50%);
    }
}

.error-box, .success-box, .info-box, .warning-box, .loading-box {
    border-radius: $border-radius;
    font-size: 14px;
    line-height: 1.5;
    font-weight: $font-weight-default;
    padding: 11px 15px 11px 50px;

    min-height: 43px;

    &.small {
        padding: 10px 15px 10px 40px;
        min-height: 39px;

        &::before {
            font-size: 20px;
            line-height: 20px;
            width: 20px;
            height: 20px;
        }
    }

    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%; // In case of button element (required to fit width)
    text-align: left; // In case of button element

    box-sizing: border-box;
    margin: 10px 0;
    position: relative;

    &:link, &:visited, &:active, &:hover, &:focus {
        text-decoration: none;
    }

    .custom-bottom-box + & {
        margin-top: 5px;
    }

    +.error-box, +.success-box, +.info-box, +.warning-box, +.loading-box  {
        margin-top: -7px;
    }

    > .button {
        &.text {
            color: inherit;

            &:disabled, &.disabled {
                opacity: 0.4;
            }
        }
    }

    @media (max-width: 400px){
        flex-direction: column;
        align-items: flex-start;
    }

    @media (min-width: 401px){
        > .button {
            flex-shrink: 0;
            margin: -15px 0;
            padding: 0;
            justify-self: stretch;
            padding-left: 15px;
            margin-left: auto;
        }
    }
}

.error-box, .success-box, .info-box, .warning-box {
    @extend .icon-base;

    &::before {
        position: absolute;
        font-size: 24px;
        left: 12px;
        top: 50%;
        transform: translate(0, -50%);
    }

    &.icon {
        display: flex;
        width: auto;
        height: auto;
        overflow: visible;
        white-space: inherit;
    }

    &.selectable {
        touch-action: manipulation;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        user-select: none;
        cursor: pointer;

        &:active {
            opacity: 0.5;
        }
    }
}