






























































































































































































































































@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.detailed-ticket-view {
    main {
        --st-horizontal-padding: 30px;

        .sheet & {
            // Force extra padding on mobile devices
            --st-horizontal-padding: 40px;
        }

        > .event-name {
            @extend .style-definition-label;
            padding-top: var(--st-horizontal-padding, 30px);
        }

        .ticket-index {
            @extend .style-definition-label;
            display: inline-block;
        }

        > h1 {
            @extend .style-title-1;
        }

        > .description {
            @extend .style-description-small;
            padding-top: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            white-space: pre-wrap;
            line-clamp: 2; /* number of lines to show */
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;

            + .description {
                padding-top: 0;
            }
        }

        > .price {
            font-size: 14px;
            line-height: 1.4;
            font-weight: 600;
            padding-top: 10px;
            color: $color-primary;
            display: flex;
            flex-direction: row;

            .style-tag {
                margin-left: auto;
            }
        }
    }

    .qr-box {
        max-width: calc(100vh - 200px);
        max-width: calc(100dvh - 200px);
        margin: 0 auto;

        > div {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                top: -30px;
                left: -30px;
                right: -30px;
                bottom: -30px;
                border-radius: $border-radius;
                background: white; // no variable here, because should be white (qr code)
                display: none;
            }
        }

        body.dark & {
            padding-top: 30px;
            padding-bottom: 10px;

            > div:before {
                display: block;
            }
        }

        body:not(.light) & {
            @media (prefers-color-scheme: dark) {
                padding-top: 30px;
                padding-bottom: 10px;

                > div:before {
                    display: block;
                }
            }
        }

        img, .placeholder {
            width: auto;
            height: auto;
            max-width: 100%;
            
        }

        img {
            position: absolute;
        }

        .placeholder {
            width: 100%;
            padding-bottom: 100%;
            padding-bottom: min(370px, 100%);
            max-width: 370px;
        }

        > span {
            display: block;
            color: $color-gray-text;
            font-size: 10px;
            font-weight: bold;
        }
    }

    .sponsor-box {
        margin-top: 10px;
        margin-bottom: -10px;
        width: 100%;

        body.dark & {
            // We need more white space around the logo in dark mode
            margin-top: 30px;
        }

        body:not(.light) & {
            @media (prefers-color-scheme: dark) {
                // We need more white space around the logo in dark mode
                margin-top: 30px;
            }
        }

        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 1fr;
    }

    .sponsor {
        grid-area: 1 / 1 / 1 / 1;
        align-self: stretch;
        opacity: 0;
        transition: opacity 0.2s;
        border-radius: $border-radius;
        pointer-events: none;

        &.visible {
            opacity: 1;
            pointer-events: auto;
        }

        > .image-component {
            max-height: 150px;
        }

        &.isLogo {
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
            border: $border-width solid $color-border;
            background: white;
            display: grid;
            align-items: center;

            >.image-component {
                max-height: 80px;
                margin: 15px 50px;
                border-radius: 0;
            }
        }
    }
}
