@font-face {
font-family: "icons";
font-display: block;
src: url("/icons.0aec07189bea21824666888264875a1f.woff2") format("woff2"),
url("/icons.ebdb47d806b99d2750fedfedac368561.woff") format("woff");
}

.icon:before, .icon-base:before {
font-family: icons !important;
font-style: normal;
font-weight: normal !important;
}

.icon.add-line:before, .icon-base.add-line:before{
content: "\f101";
}
.icon.add:before, .icon-base.add:before{
content: "\f102";
}
.icon.admin:before, .icon-base.admin:before{
content: "\f103";
}
.icon.archive-layer-1:before, .icon-base.archive-layer-1:before{
content: "\f104";
}
.icon.archive-layer-2:before, .icon-base.archive-layer-2:before{
content: "\f105";
}
.icon.archive:before, .icon-base.archive:before{
content: "\f106";
}
.icon.arrow-back-ios:before, .icon-base.arrow-back-ios:before{
content: "\f107";
}
.icon.arrow-back:before, .icon-base.arrow-back:before{
content: "\f108";
}
.icon.arrow-down-small:before, .icon-base.arrow-down-small:before{
content: "\f109";
}
.icon.arrow-down:before, .icon-base.arrow-down:before{
content: "\f10a";
}
.icon.arrow-left:before, .icon-base.arrow-left:before{
content: "\f10b";
}
.icon.arrow-right-small:before, .icon-base.arrow-right-small:before{
content: "\f10c";
}
.icon.arrow-right:before, .icon-base.arrow-right:before{
content: "\f10d";
}
.icon.arrow-swap:before, .icon-base.arrow-swap:before{
content: "\f10e";
}
.icon.arrow-up-small:before, .icon-base.arrow-up-small:before{
content: "\f10f";
}
.icon.arrow-up:before, .icon-base.arrow-up:before{
content: "\f110";
}
.icon.attachment:before, .icon-base.attachment:before{
content: "\f111";
}
.icon.basket-layer-1:before, .icon-base.basket-layer-1:before{
content: "\f112";
}
.icon.basket-layer-2:before, .icon-base.basket-layer-2:before{
content: "\f113";
}
.icon.basket:before, .icon-base.basket:before{
content: "\f114";
}
.icon.bold:before, .icon-base.bold:before{
content: "\f115";
}
.icon.book:before, .icon-base.book:before{
content: "\f116";
}
.icon.calculator-layer-1:before, .icon-base.calculator-layer-1:before{
content: "\f117";
}
.icon.calculator-layer-2:before, .icon-base.calculator-layer-2:before{
content: "\f118";
}
.icon.calculator:before, .icon-base.calculator:before{
content: "\f119";
}
.icon.camera-line:before, .icon-base.camera-line:before{
content: "\f11a";
}
.icon.camera:before, .icon-base.camera:before{
content: "\f11b";
}
.icon.canceled:before, .icon-base.canceled:before{
content: "\f11c";
}
.icon.card:before, .icon-base.card:before{
content: "\f11d";
}
.icon.category:before, .icon-base.category:before{
content: "\f11e";
}
.icon.circle:before, .icon-base.circle:before{
content: "\f11f";
}
.icon.clock-small:before, .icon-base.clock-small:before{
content: "\f120";
}
.icon.clock:before, .icon-base.clock:before{
content: "\f121";
}
.icon.close:before, .icon-base.close:before{
content: "\f122";
}
.icon.collapse-left:before, .icon-base.collapse-left:before{
content: "\f123";
}
.icon.copy:before, .icon-base.copy:before{
content: "\f124";
}
.icon.crown-small:before, .icon-base.crown-small:before{
content: "\f125";
}
.icon.crown:before, .icon-base.crown:before{
content: "\f126";
}
.icon.disabled-person:before, .icon-base.disabled-person:before{
content: "\f127";
}
.icon.dot:before, .icon-base.dot:before{
content: "\f128";
}
.icon.download:before, .icon-base.download:before{
content: "\f129";
}
.icon.drag:before, .icon-base.drag:before{
content: "\f12a";
}
.icon.earth:before, .icon-base.earth:before{
content: "\f12b";
}
.icon.edit:before, .icon-base.edit:before{
content: "\f12c";
}
.icon.education:before, .icon-base.education:before{
content: "\f12d";
}
.icon.email:before, .icon-base.email:before{
content: "\f12e";
}
.icon.empty:before, .icon-base.empty:before{
content: "\f12f";
}
.icon.environment:before, .icon-base.environment:before{
content: "\f130";
}
.icon.error:before, .icon-base.error:before{
content: "\f131";
}
.icon.exclamation-two:before, .icon-base.exclamation-two:before{
content: "\f132";
}
.icon.exclamation:before, .icon-base.exclamation:before{
content: "\f133";
}
.icon.external:before, .icon-base.external:before{
content: "\f134";
}
.icon.eye:before, .icon-base.eye:before{
content: "\f135";
}
.icon.feedback-line:before, .icon-base.feedback-line:before{
content: "\f136";
}
.icon.feedback:before, .icon-base.feedback:before{
content: "\f137";
}
.icon.file-excel:before, .icon-base.file-excel:before{
content: "\f138";
}
.icon.file-filled:before, .icon-base.file-filled:before{
content: "\f139";
}
.icon.file-image:before, .icon-base.file-image:before{
content: "\f13a";
}
.icon.file-pdf:before, .icon-base.file-pdf:before{
content: "\f13b";
}
.icon.file-word:before, .icon-base.file-word:before{
content: "\f13c";
}
.icon.file:before, .icon-base.file:before{
content: "\f13d";
}
.icon.filter:before, .icon-base.filter:before{
content: "\f13e";
}
.icon.flag-layer-1-1:before, .icon-base.flag-layer-1-1:before{
content: "\f13f";
}
.icon.flag-layer-1:before, .icon-base.flag-layer-1:before{
content: "\f140";
}
.icon.flag-layer-2:before, .icon-base.flag-layer-2:before{
content: "\f141";
}
.icon.flag:before, .icon-base.flag:before{
content: "\f142";
}
.icon.flashlight:before, .icon-base.flashlight:before{
content: "\f143";
}
.icon.flip-vertical:before, .icon-base.flip-vertical:before{
content: "\f144";
}
.icon.gift:before, .icon-base.gift:before{
content: "\f145";
}
.icon.group-layer-1:before, .icon-base.group-layer-1:before{
content: "\f146";
}
.icon.group-layer-2:before, .icon-base.group-layer-2:before{
content: "\f147";
}
.icon.group:before, .icon-base.group:before{
content: "\f148";
}
.icon.h1:before, .icon-base.h1:before{
content: "\f149";
}
.icon.h2:before, .icon-base.h2:before{
content: "\f14a";
}
.icon.h3:before, .icon-base.h3:before{
content: "\f14b";
}
.icon.help:before, .icon-base.help:before{
content: "\f14c";
}
.icon.hr:before, .icon-base.hr:before{
content: "\f14d";
}
.icon.image:before, .icon-base.image:before{
content: "\f14e";
}
.icon.info-circle:before, .icon-base.info-circle:before{
content: "\f14f";
}
.icon.info-filled:before, .icon-base.info-filled:before{
content: "\f150";
}
.icon.info-text:before, .icon-base.info-text:before{
content: "\f151";
}
.icon.info:before, .icon-base.info:before{
content: "\f152";
}
.icon.invoice:before, .icon-base.invoice:before{
content: "\f153";
}
.icon.italic:before, .icon-base.italic:before{
content: "\f154";
}
.icon.key-lost:before, .icon-base.key-lost:before{
content: "\f155";
}
.icon.key:before, .icon-base.key:before{
content: "\f156";
}
.icon.language:before, .icon-base.language:before{
content: "\f157";
}
.icon.lightning:before, .icon-base.lightning:before{
content: "\f158";
}
.icon.link:before, .icon-base.link:before{
content: "\f159";
}
.icon.links:before, .icon-base.links:before{
content: "\f15a";
}
.icon.location:before, .icon-base.location:before{
content: "\f15b";
}
.icon.lock-missing:before, .icon-base.lock-missing:before{
content: "\f15c";
}
.icon.lock:before, .icon-base.lock:before{
content: "\f15d";
}
.icon.logout:before, .icon-base.logout:before{
content: "\f15e";
}
.icon.menu:before, .icon-base.menu:before{
content: "\f15f";
}
.icon.min:before, .icon-base.min:before{
content: "\f160";
}
.icon.more-android:before, .icon-base.more-android:before{
content: "\f161";
}
.icon.more-apple:before, .icon-base.more-apple:before{
content: "\f162";
}
.icon.more:before, .icon-base.more:before{
content: "\f163";
}
.icon.new:before, .icon-base.new:before{
content: "\f164";
}
.icon.ol:before, .icon-base.ol:before{
content: "\f165";
}
.icon.paragraph:before, .icon-base.paragraph:before{
content: "\f166";
}
.icon.pencil:before, .icon-base.pencil:before{
content: "\f167";
}
.icon.play:before, .icon-base.play:before{
content: "\f168";
}
.icon.plus:before, .icon-base.plus:before{
content: "\f169";
}
.icon.power:before, .icon-base.power:before{
content: "\f16a";
}
.icon.privacy:before, .icon-base.privacy:before{
content: "\f16b";
}
.icon.qr-code:before, .icon-base.qr-code:before{
content: "\f16c";
}
.icon.redo:before, .icon-base.redo:before{
content: "\f16d";
}
.icon.retry:before, .icon-base.retry:before{
content: "\f16e";
}
.icon.reverse:before, .icon-base.reverse:before{
content: "\f16f";
}
.icon.search:before, .icon-base.search:before{
content: "\f170";
}
.icon.seat:before, .icon-base.seat:before{
content: "\f171";
}
.icon.settings:before, .icon-base.settings:before{
content: "\f172";
}
.icon.share-apple:before, .icon-base.share-apple:before{
content: "\f173";
}
.icon.share:before, .icon-base.share:before{
content: "\f174";
}
.icon.star-line:before, .icon-base.star-line:before{
content: "\f175";
}
.icon.star:before, .icon-base.star:before{
content: "\f176";
}
.icon.stats:before, .icon-base.stats:before{
content: "\f177";
}
.icon.success-line:before, .icon-base.success-line:before{
content: "\f178";
}
.icon.success:before, .icon-base.success:before{
content: "\f179";
}
.icon.sync:before, .icon-base.sync:before{
content: "\f17a";
}
.icon.text-style:before, .icon-base.text-style:before{
content: "\f17b";
}
.icon.ticket:before, .icon-base.ticket:before{
content: "\f17c";
}
.icon.trash:before, .icon-base.trash:before{
content: "\f17d";
}
.icon.ul:before, .icon-base.ul:before{
content: "\f17e";
}
.icon.underline:before, .icon-base.underline:before{
content: "\f17f";
}
.icon.undo:before, .icon-base.undo:before{
content: "\f180";
}
.icon.unregister:before, .icon-base.unregister:before{
content: "\f181";
}
.icon.upload:before, .icon-base.upload:before{
content: "\f182";
}
.icon.user-admin-layer-1:before, .icon-base.user-admin-layer-1:before{
content: "\f183";
}
.icon.user-admin-layer-2:before, .icon-base.user-admin-layer-2:before{
content: "\f184";
}
.icon.user-blocked-layer-1:before, .icon-base.user-blocked-layer-1:before{
content: "\f185";
}
.icon.user-blocked-layer-2:before, .icon-base.user-blocked-layer-2:before{
content: "\f186";
}
.icon.user-blocked:before, .icon-base.user-blocked:before{
content: "\f187";
}
.icon.user:before, .icon-base.user:before{
content: "\f188";
}
.icon.wand:before, .icon-base.wand:before{
content: "\f189";
}
.icon.warning:before, .icon-base.warning:before{
content: "\f18a";
}
.icon.wifi:before, .icon-base.wifi:before{
content: "\f18b";
}

.error-box:before {
content: "\f131";
}
.info-box:before {
content: "\f152";
}
.success-box:before {
content: "\f179";
}
.warning-box:before {
content: "\f18a";
}

body.web-android .icon.more:before, body.native-android .icon.more:before, 
body.web-android .icon-base.more:before, body.native-android .icon-base.more:before {
content: "\f161";
}

body.web-iOS .icon.arrow-back:before, body.native-iOS .icon.arrow-back:before,
body.web-iOS .icon-base.arrow-back:before, body.native-iOS .icon-base.arrow-back:before {
content: "\f107";
}

body.web-iOS .icon.more:before, body.native-iOS .icon.more:before, body.web-macOS .icon.more:before,
body.web-iOS .icon-base.more:before, body.native-iOS .icon-base.more:before, body.web-macOS .icon-base.more:before {
content: "\f162";
}
body.web-iOS .icon.share:before, body.native-iOS .icon.share:before, body.web-macOS .icon.share:before,
body.web-iOS .icon-base.share:before, body.native-iOS .icon-base.share:before, body.web-macOS .icon-base.share:before {
content: "\f173";
}
