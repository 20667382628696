






















































































































































































































@use "~@stamhoofd/scss/base/variables.scss" as *;
@use '~@stamhoofd/scss/base/text-styles.scss';

.centered-message {
    position: fixed;
    z-index: 10000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @extend .style-overlay-shadow;
    border-radius: $border-radius-modals;
    background: $color-background;
    max-width: calc(100vw - 30px);
    width: 350px;
    
    box-sizing: border-box;
    max-height: 100vh;
    overflow: auto;
    overflow-x: hidden;

    @media (max-width: 551px) {
        padding: 20px;

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-top: 20px;
        }
    }

    @media (min-width: 550px) {
        width: 550px;
        padding: 30px 40px;

        .buttons {
            border-top: $border-width-thin solid $color-border-shade;
            padding: 15px 40px 15px 40px;
            margin: 25px -40px -30px -40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex-direction: row-reverse;
            gap: 10px;
        }
    }

    > *:first-child {
        margin-top: 10px;
    }
    
    > img.center, > .icon.center {
        display: block;
        margin: 0 auto;
    }

    > .loading-button {
        display: block;
    }

    > h1 {
        padding-bottom: 10px;
        text-align: left;
        @extend .style-title-2;

        + p {
            @extend .style-description;
        }
    }
}

.centered-message-container {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;

    @media (prefers-color-scheme: dark) {
        background: rgba(8, 8, 8, 0.7);
    }

    &.show-enter-active {
        transition: opacity 0.30s;

        > .centered-message  {
            transition: transform 0.30s cubic-bezier(0.0, 0.0, 0.2, 1);
        }
    }


    &.show-leave-active {
        transition: opacity 0.25s;

        > .centered-message  {
            transition: transform 0.25s cubic-bezier(0.4, 0.0, 1, 1);
        }
    }

    &.show-enter, &.show-leave-to /* .fade-leave-active below version 2.1.8 */ {
        // Instant appearing context menu! (only leave animation)
        opacity: 0;

        > .centered-message  {
            transform: translate(-50%, 50vh);
        }
    }
}

